/* use and edit public/css/app.css */
.main-banner .slick-track {
    height: 100vh;
}

#calculator .pal-range__input input {
    background-color: transparent;
    max-width: 65%;
}

.pal-input__placeholder > span,
.pal-form__list-item,
.pal-checkbox__text {
    color: rgba(0, 0, 0, 0.8) !important;
}

.pal-progress__step {
    opacity: 0.8 !important;
}

.pal-articles__item {
    width: 100%;
}

.pal-content ul > li.file:before {
    background-color: transparent !important;
}

.pal-filter__item_custom {
    position: relative;
    margin-right: 1rem;
    margin-bottom: 1rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 4px;
    border: 1px solid rgba(0,0,0,0.08);
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    color: #000;
    cursor: pointer;
    white-space: nowrap;
}

.pal-filter__item_custom_active {
    position: relative;
    margin-right: 1rem;
    margin-bottom: 1rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    cursor: pointer;
    white-space: nowrap;
    border-color: #0c2d70;
    background-color: #0c2d70;
    color: #fff;
    font-weight: 700;
}

.pal-filter__item_custom_disabled {
    position: relative;
    margin-right: 1rem;
    margin-bottom: 1rem;
    height: 2rem;
    line-height: 2rem;
    border-radius: 4px;
    padding-left: 1rem;
    padding-right: 1rem;
    font-size: 0.875rem;
    cursor: pointer;
    white-space: nowrap;
    border-color: #f5f7f7;
    background-color: #f5f7f7;
    color: #000;
    font-weight: 400;
}

.pal-slider__title_custom {
    font-weight: 900;
    font-size: 4rem;
    line-height: 1.0625em;
    margin-bottom: 0.875rem;
}

@media only screen and (min-width: 769px) and (max-width: 1024px) {
    .pal-slider__title_custom {
        font-size: 3.5rem;
    }
}

@media only screen and (max-width: 768px) {
    .pal-slider__title_custom {
        font-size: 1.75rem;
    }
}

.product_specs .pal-details__item .pal-details__title,
.product_specs .pal-details__item .pal-details__value {
    width: 50%;
}

.product_specs .pal-details__item .pal-details__title {
    padding-right: 10px;
}

/* list styles */
.product_detail ol,
.pal-nums-list__text ol {
    counter-reset: item;
    padding-left: 0;
}

.product_detail ol li:first-child,
.pal-nums-list__text ol li:first-child {
    margin-top: 0;
}

.product_detail ol li,
.pal-nums-list__text ol li {
    padding-left: 3rem;
    position: relative;
    line-height: 1.55em;
    margin-bottom: 1.55em;
    margin-top: 0.55em;
}

.product_detail ol > li:before,
.pal-nums-list__text ol > li:before {
    line-height: unset;
    left: 0;
    font-size: 1.125rem;
    color: #000;
    font-weight: 700;
    width: auto;
}
.product_detail ol li:before,
.pal-nums-list__text ol li:before {
    content: " ";
    display: block;
    content: counter(item, decimal) " ";
    counter-increment: item;
    display: block;
    position: absolute;
    top: 0;
    left: 0;
    line-height: 1.5rem;
    text-align: left;
    font-size: 1.5rem;
    width: 1.5rem;
    color: #0c2d70;
}

.product_detail ul, .product_detail ol,
.pal-nums-list__text ul, .pal-nums-list__text ol {
    margin-top: 1.55em;
    margin-bottom: 1.55em;
}

.product_detail ul li:first-child,
.pal-nums-list__text ul li:first-child {
    margin-top: 0;
}

.product_detail ul li,
.pal-nums-list__text ul li {
    padding-left: 36px;
    position: relative;
    line-height: 1.55em;
    margin-bottom: 1.55em;
    margin-top: 0.55em;
}

.product_detail ul > li,
.pal-nums-list__text ul > li {
    padding-left: 30px;
}

.product_detail ol li, .product_detail ul li,
.pal-nums-list__text ol li, .pal-nums-list__text ul li {
    margin-bottom: 1em;
    padding-left: 30px;
    line-height: 1.3em;
}

.product_detail ul > li:before,
.pal-nums-list__text ul > li:before {
    border-radius: 50%;
    width: 10px;
    max-width: 10px;
    min-width: 10px;
    height: 10px;
    max-height: 10px;
    min-height: 10px;
    background-color: #000;
    top: 0.4em;
}

.product_detail ul li:before,
.pal-nums-list__text ul li:before {
    content: " ";
    display: block;
    position: absolute;
    left: 0;
    width: 20px;
    top: 0.4em;
    height: 1px;
    background-color: #0c2d70;
}

table.pal-table--strip2 tr.hover td {
    background-color: #F5F7F7 !important;
}

.calculator-container {
    display: none;
}

.calculator-container.pal-active {
    display: block;
}

#calculator .pal-selectbox,
.pal-section--dark .calculator-container {
    color: #000;
}

.table-wrap {
    overflow: hidden !important;
}

.tippy-box[data-animation=fade][data-state=hidden] {
    opacity: 0
}

[data-tippy-root] {
    max-width: calc(100vw - 10px)
}

.tippy-box {
    position: relative;
    background-color: #f5f7f7;
    border-radius: 4px;
    line-height: 1.4;
    outline: 0;
    transition-property: transform, visibility, opacity
}

.tippy-box[data-placement^=top] > .tippy-arrow {
    bottom: 0
}

.tippy-box[data-placement^=top] > .tippy-arrow:before {
    bottom: -7px;
    left: 0;
    border-width: 8px 8px 0;
    border-top-color: initial;
    transform-origin: center top
}

.tippy-box[data-placement^=bottom] > .tippy-arrow {
    top: 0
}

.tippy-box[data-placement^=bottom] > .tippy-arrow:before {
    top: -7px;
    left: 0;
    border-width: 0 8px 8px;
    border-bottom-color: initial;
    transform-origin: center bottom
}

.tippy-box[data-placement^=left] > .tippy-arrow {
    right: 0
}

.tippy-box[data-placement^=left] > .tippy-arrow:before {
    border-width: 8px 0 8px 8px;
    border-left-color: initial;
    right: -7px;
    transform-origin: center left
}

.tippy-box[data-placement^=right] > .tippy-arrow {
    left: 0
}

.tippy-box[data-placement^=right] > .tippy-arrow:before {
    left: -7px;
    border-width: 8px 8px 8px 0;
    border-right-color: initial;
    transform-origin: center right
}

.tippy-box[data-inertia][data-state=visible] {
    transition-timing-function: cubic-bezier(.54, 1.5, .38, 1.11)
}

.tippy-arrow {
    width: 16px;
    height: 16px;
    color: #ccc;
}

.tippy-arrow:before {
    content: "";
    position: absolute;
    border-color: transparent;
    border-style: solid
}

.tippy-content {
    position: relative;
    padding: 5px 9px;
    z-index: 1
}

.sitemap-block {
    padding: 10px 0;
}

.pal-tabs__item.highlight .pal-accordeon__heading,
.pal-accordeon__item.highlight .pal-accordeon__heading {
    background-color: #0c2d70;
    border-radius: 12px;
}

.pal-tabs__item.highlight:hover .pal-accordeon__heading,
.pal-accordeon__item.highlight:hover .pal-accordeon__heading {
    background-color: #fff;
}

.pal-tabs__item.highlight .pal-accordeon__icon,
.pal-accordeon__item.highlight .pal-accordeon__icon {
    color: #fff;
}

.pal-tabs__item.highlight:hover .pal-accordeon__icon,
.pal-accordeon__item.highlight:hover .pal-accordeon__icon {
    color: #000;
}

.pal-tabs__item.highlight.pal-active .pal-accordeon__heading,
.pal-accordeon__item.highlight.pal-active .pal-accordeon__heading {
    margin-bottom: 2rem;
}

.pal-tabs__item.highlight .pal-accordeon__title,
.pal-accordeon__item.highlight .pal-accordeon__title {
    color: #fff;
}

.pal-tabs__item.highlight:hover .pal-accordeon__title,
.pal-accordeon__item.highlight:hover .pal-accordeon__title {
    color: #000;
}

.pal-programs__item.highlight {
    background-color: #0c2d70;
    color: #fff;
}

.pal-programs__item.highlight .pal-btn--text {
    color: #fff;
}

.pal-programs__item.highlight:hover {
    color: #000;
}

.pal-programs__item.highlight:hover .pal-btn--text {
    color: #0c2d70;
}

.container_custom,
.container_recaptcha {
    position: relative;
}

.container_recaptcha .pal-input__error {
    top: 100%
}
.__zaprosWidget {
    position: fixed;
    bottom: 0;
    right: 0;
}

.achievements .pal-articles--property .pal-articles__image {
    padding-bottom: 157%;
    border-radius: 0;
}
.achievements .pal-articles--slider .pal-articles__item {
    max-width: 135px;
}

.pal-section__title {
    margin-bottom: 1.3em !important;
}

.pal-calendar .datepicker--cell-day.payment {
    background-color: #6d85b5;
    border-radius: 50%;
    color: #fff;
}

.money {
    white-space: nowrap;
}

.direkcia .pal-table tr.extra td:first-child {
    width: 25%;
}

@media print {
    .buttons_container,
    .pal-back,
    .pal-section__title,
    .pal-footer,
    .pal-header {
        display: none;
    }

    /*.account .col.part-6,*/
    /*.account .col.part-2 .pal-section__title,*/
    /*.pal-section__title--main,*/
    /*.pal-calendar__datepicker,*/
    /*.pal-calendar--mobile,*/
    /*.pal-section > .pal-container,*/
    /*.pal-sections__list {*/
    /*    display: none;*/
    /*}*/

    /*body.account * {*/
    /*    display: none;*/
    /*}*/
    /*#section-to-print,*/
    /*#section-to-print * {*/
    /*    display: inline-block;*/
    /*}*/
    #section-to-print {
        position: absolute;
        left: 0;
        top: 0;
        min-width: 980px;
    }

    .c_info .col {
        padding-right: 50px;
    }
}

#authentication_button {
    min-width: 270px;
}

.loader {
    border: 2px solid #f3f3f3; /* Light grey */
    border-top: 2px solid #102d8c; /* Blue */
    border-radius: 50%;
    width: 20px;
    height: 20px;
    animation: spin 2s linear infinite;
    margin: -2px 5px;
    display: inline-block;
}

.calendar_print {
    position: absolute;
    top: 20px;
    right: 10px;
}

.pal-calendar--mobile .calendar_print {
    position: absolute;
    top: 50px;
    right: 15px;
}

.calendar_print:hover {
    cursor: pointer;
}

@keyframes spin {
    0% { transform: rotate(0deg); }
    100% { transform: rotate(360deg); }
}

@media only screen and (max-width: 1024px) {
    .pal-back__link {
        padding-left: 0 !important;
    }

    .pal-back__link:first-child {
        padding-left: 1.5rem !important;
    }
}